import React from 'react';
import styled from 'styled-components';

const CompanyOverview = () => {
  return (
    <OverviewWrapper>
      <OverviewContent>
        <SectionTitle>Orchestrate AI Agents to Get Work Done</SectionTitle>
        <Description>
          Harness the power of multiple AI agents working together - no coding required. Simply describe your tasks, arrange your agents, and watch as they collaborate to accomplish your goals. From research to content creation, your custom AI workforce is ready to tackle any challenge.
        </Description>
        <ValuePropositions>
          <ValueItem>
            <ValueIcon>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 2L2 7L12 12L22 7L12 2Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M2 17L12 22L22 17" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M2 12L12 17L22 12" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </ValueIcon>
            <ValueText>Multi-Agent Workflows</ValueText>
            <ValueDescription>Create powerful workflows by combining specialized AI agents that work together seamlessly to complete complex tasks.</ValueDescription>
          </ValueItem>
          <ValueItem>
            <ValueIcon>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17 21V19C17 17.9391 16.5786 16.9217 15.8284 16.1716C15.0783 15.4214 14.0609 15 13 15H5C3.93913 15 2.92172 15.4214 2.17157 16.1716C1.42143 16.9217 1 17.9391 1 19V21" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M9 11C11.2091 11 13 9.20914 13 7C13 4.79086 11.2091 3 9 3C6.79086 3 5 4.79086 5 7C5 9.20914 6.79086 11 9 11Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M23 21V19C22.9993 18.1137 22.7044 17.2528 22.1614 16.5523C21.6184 15.8519 20.8581 15.3516 20 15.13" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M16 3.13C16.8604 3.35031 17.623 3.85071 18.1676 4.55232C18.7122 5.25392 19.0078 6.11683 19.0078 7.005C19.0078 7.89318 18.7122 8.75608 18.1676 9.45769C17.623 10.1593 16.8604 10.6597 16 10.88" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </ValueIcon>
            <ValueText>No-Code Automation</ValueText>
            <ValueDescription>Design custom workflows through natural conversation - tell your agents what you need and they'll handle the rest.</ValueDescription>
          </ValueItem>
          <ValueItem>
            <ValueIcon>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M12 16L16 12L12 8" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M8 12H16" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </ValueIcon>
            <ValueText>Custom Agent Jobs</ValueText>
            <ValueDescription>Create and save custom jobs that your AI agents can execute repeatedly, from research to report generation.</ValueDescription>
          </ValueItem>
        </ValuePropositions>
      </OverviewContent>
    </OverviewWrapper>
  );
};

const OverviewWrapper = styled.section`
  background-color: ${props => props.theme.colors.background};
  padding: 6rem 0;
`;

const OverviewContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  text-align: center;
`;

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  color: ${props => props.theme.colors.primary};
  margin-bottom: 2rem;
  font-weight: 300;
`;

const Description = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 3rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  color: ${props => props.theme.colors.text};
`;

const ValuePropositions = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 2rem;
`;

const ValueItem = styled.div`
  flex: 1;
  min-width: 280px;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const ValueIcon = styled.div`
  font-size: 3rem;
  margin-bottom: 1rem;
  color: ${props => props.theme.colors.secondary};
`;

const ValueText = styled.h3`
  font-size: 1.2rem;
  font-weight: 600;
  color: ${props => props.theme.colors.text};
  margin-bottom: 0.5rem;
`;

const ValueDescription = styled.p`
  font-size: 1rem;
  color: ${props => props.theme.colors.text};
  opacity: 0.8;
  max-width: 300px;
`;

export default CompanyOverview;
